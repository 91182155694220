import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getCartPromo, removeFromCart } from '../actions/cartActions';
import HeaderCart from '../components/HeaderCart';
import Alert_promo from '../components/alert_promo';
import useAxiosInterceptors from '../axios/useAxios';
import PageLoader from '../components/loader/PageLoader';
import CartLength from '../components/CartLength';
import Checkout from '../payments/Checkout';
import { getConecsInstrument } from '../actions/userConecsActions';
import PromoCarte from '../components/promo_carte'
import { clearEdenred, getEdenredBalance, updateEdenred } from '../actions/userEdenredActions';
import StepBar from '../components/Step_bar';
import v from '../styles/img/arrow/v.svg'
import { selectPayment } from '../actions/payMethodActions';
import { findUser } from '../actions/userActions';
import BackButton from '../components/button/BackButton';
import { PROMO, SCAN, SCAN_CHECK } from '../constants/routes';
import { formatPrice, formatPriceWithEuro } from '../utils/StringUtils';
import { isAllowedToPay, isIntegrated, isTRavailable } from '../utils/Validator';
import { USER_EDENRED_BALANCE_REQUEST, USER_EDENRED_BALANCE_TIMEOUT } from '../constants/userConstants';
import { getErrorMessage } from '../utils/WsUtils';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { notifyDoubleCallback, notifyError } from '../utils/Notify';
import SuccessNotif from '../components/SuccessNotif';
import { getOrderAmount } from '../utils/PriceUtils';
import {CheckoutProvider} from "../context/checkoutProvider";
import { Skeleton } from '../components/Skeleton';
import magicBrush from '../styles/img/wallet/magicBrush.svg';
import fidelityCheck from '../styles/img/wallet/fidelityCheck.svg';
const CartScreen = () => {

  window.scrollTo(0, 0);
  localStorage.setItem('promoSolde', false);

  // const date = Time()

  // const axiosInstance = Config()
  const axiosInstance = useAxiosInterceptors();
  const navigate = useNavigate();
  const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  // const orderCreate = useSelector(state => state.orderCreate)

  const { cartItems } = useSelector((state) => state.cart);
  // const cartItems = {"_id":"659bee17f508cf70c6005c95","storeId":"63e504916b4d0ed10a2b7b6a","itemsPrice":12.23,"itemsPrice_TR":0,"promo":"","promoPrice":0,"isPaid":false,"cartScreen":false,"orderScreen":false,"verification":false,"orderItems":[{"name":"T-Shirt Gamme VIP","image":"https://i.ibb.co/4STcsb9/Tee-shirt-Gamme-VIP.jpg","price":12.23,"Code_Barre":"3606804931990","Qty":1,"_id":"659bf01bf508cf70c6005cb0"}, {"name":"T-Shirt Gamme VIP","image":"https://i.ibb.co/4STcsb9/Tee-shirt-Gamme-VIP.jpg","price":12.23,"Code_Barre":"3606804931990","Qty":1,"_id":"659bf01bf508cf70c6005cb0"}, ],"scanItems":[{"name":"T-Shirt Gamme VIP","image":"https://i.ibb.co/4STcsb9/Tee-shirt-Gamme-VIP.jpg","price":12.23,"Code_Barre":"3606804931990","Qty":1,"storeId":"63e504916b4d0ed10a2b7b6a","_id":"655536421a0280e59f6a2599","__v":0}],"deleteItems":[],"createdAt":"2024-01-08T12:44:07.457Z","updatedAt":"2024-01-08T12:52:43.486Z","__v":2,"ageRestriction":""}

  // cart.itemsPrice = toPrice(cartItems.reduce((a,c) => a+c.price, 0))
  // cart.promoprice = promoprice
  //const loading = false;

  const { loadingCart } = useSelector((state) => state.cart);
  const { store } = useSelector((state) => state.store);
  const user = useSelector((state) => state.user)
  const orderPay = useSelector((state) => state.orderPay);
  const dispatch = useDispatch();
  const edenred = useSelector((state) => state.user.edenred)
  const conecs = useSelector((state) => state.conecs)
  const [checkedValues, setCheckedValues] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [localQty, setLocalQty] = useState([]);
  const [localTotal, setLocalTotal] = useState();
  const countItems = CartLength();
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [edenredErrorMessage, setEdenredErrorMessage] = useState('');
  const [edenredIsLoading, setEdenredIsLoading] = useState(false);
  const [validationLoaded, setValidationLoaded] = useState(false);
  const isPayzenModule = store?.paymentGateway === 'payzen'; // TODO : rename
  const [isPayzenApplePayLoading, setIsPayzenApplePayLoading] = useState(isPayzenModule);
  useEffect(() => {
    if (edenred?.balance) {
      dispatch({ type: USER_EDENRED_BALANCE_REQUEST })
      setEdenredIsLoading(true);
      getEdenredBalance(axiosInstance, edenred, user._id)
        .then((response) => dispatch(updateEdenred(response.data)))
        .catch((error) => {
          if (error?.response?.data?.errorCode === 'EDENRED_TIMEOUT') {
            dispatch({ type: USER_EDENRED_BALANCE_TIMEOUT });
          } else {
            dispatch(clearEdenred(error));
            setEdenredErrorMessage(getErrorMessage(error));
          }
        })
        .finally(() => setEdenredIsLoading(false));
    }
    if (conecs?.balance) {
      dispatch(getConecsInstrument(axiosInstance, conecs));
    }
    dispatch(selectPayment(''))
    window.scrollTo(0, 0)

    if (user && user._id) {
      dispatch(findUser(user, axiosInstance, navigate))
    }
  }, [])

  useEffect(async() => {
    if (cartItems && isIntegrated(store)) {
      axiosInstance.get(`apiws/validation-available/${store.id}`)

                   .catch((error) => {
                     // notifyError(
                     //   'Service indisponible',
                     //   'Nos services sont indisponibles pour le moment. Nous t\'invitons à réessayer plus tard',
                     //   onBack
                     // );
                     // notifyDoubleCallbackk'Service')
                     if (user?.fidCard?.length > 0) {
                       notifyDoubleCallback(
                         'Fidélité en différé',
                         'Le service fidélité de match est momentanément indisponible, le cumul de tes avantages se fera en différé',
                         'Warning',
                         'D\'accord, passer au paiement!',
                         'Je réessaye plus tard',
                         'Merci de ta compréhension',
                         'Tu peux procéder au paiement 😁',
                         () => {
                           return
                         },
                         'On est sur le coup ! 🚀',
                         'Nous t\'invitions à réessayer plus tard',
                         () => navigate(SCAN)

                       )
                     }
                   })
                   .then(async() => {
                           await dispatch(getCartPromo(cartItems._id, user, axiosInstance))
                   })
      .finally(() => setValidationLoaded(true));
    }else{
      setValidationLoaded(true)
    }
  }, []);

  useEffect(() => {
    let total = 0;
    cartItems.orderItems.map((item, index) => {
      total += item.price * (localQty[index] || item.Qty);
    });

    setLocalTotal(total);
  }, [localQty, cartItems]);

  const accumulation = (element, chiffre) => {
    const totalIterations = chiffre;
    const apres_virgule = chiffre.toString().split('.')[1];
    const totalTime = 500;
    const delay = totalTime / totalIterations;

    // for (let a = 0; a < chiffre; a++) {
    //   setTimeout(() => {
    //     element.innerText = `${a+"."+apres_virgule}`;
    //   }, delay * a);
    // }
    element.classList.add('price_animation');
    setTimeout(() => {
      element.classList.remove('price_animation');
    }, 1000);
  }

  if (document.querySelector('.price')) {
    accumulation(document.querySelector('.price'), formatPrice(cartItems.itemsPrice - cartItems.promoPrice));
  }

  const deleteItem = (item, index) => {
    let newLocalQty = [...localQty];
    newLocalQty[index] = (newLocalQty[index] || item.Qty) - 1;

    setLocalQty(newLocalQty);

    removeFromCartHandler([{ product: item, qty: 1 }]);
  };

  async function removeFromCartHandler(product_qty) {
    const cbarre_qty = product_qty.map(({ product, qty }) => ({
      Code_Barre: product.Code_Barre,
      qty: qty,
    }));

    dispatch(removeFromCart(cbarre_qty, cartItems._id, product_qty, axiosInstance));
    // .then(() => {
    //   dispatch(getCartInfo(order._id, axiosInstance));
    //   axiosInstance.put("/track/cartscreen", { id: order._id });
    // });

    setCheckedValues([]);
    setSelectAll(false);
  }

  const truncate = (str, n) => {

    return str.length > n ? str.slice(0, n - 3) + '...' : str;
  };

  const deletePopup = () => {
    let element = document.getElementById('deletePopup');
    if (element.style.visibility === 'hidden') {
      element.style.visibility = 'visible';
    } else {
      element.style.visibility = 'hidden';
    }
  };

  useEffect(() => {
    if (cartItems.isPaid === true) {
      // navigate(`/ScanCheck`);
      setPopupSuccess(true);
    }
  }, [cartItems]);

  const isAllowedToPurchase = isAllowedToPay(cartItems);

  const onBack = () => {
    // window?.paygreenjs?.unmount();
    if(store?.id === '65e6388eb6667e3400b5b8d8')navigate(SCAN);
    else navigate(PROMO);
  };

  return (
    <>
      {!cartItems ? (
        <PageLoader />
      ) : (
        <>
          {cartItems === undefined ? (
            <>{navigate('/')}</>
          ) : (
            <>
              {popupSuccess ? (
                <>
                  <SuccessNotif
                    actioner={() => {
                      navigate(SCAN_CHECK)
                    }}
                  />
                </>
              ) : (
                <></>
              )}
              <PromoCarte />
              <div className="h-full overflow-auto cartscreen" id="cartscreen">
                <Alert_promo />
                <div className="global-header">
                  <BackButton onClick={onBack} />
                  <HeaderCart titre="Paiement" />
                </div>

                { loadingCart || !validationLoaded || isPayzenApplePayLoading ? <></> :
                  <StepBar step={2} />}

                {cartItems.orderItems.length === 0 ? (
                  <>
                    <div className="w-full h-full flex flex-col p-16 items-center gap-5">
                      <h1>Panier vide</h1>
                      <Link
                        className="cart_empty text-2xl text-center px-12 "
                        to="/scan"
                      >
                        Retour au scanner {'>'}
                      </Link>
                    </div>
                    {/* <FooterNavbar props={{ cart: true }} /> */}
                  </>
                ) : (
                  <>
                    {cartItems ? (
                      <>
                        {loadingCart || !validationLoaded || isPayzenApplePayLoading?  (
                          <>
                            <div
                              className="cart-screen-skeleton"
                            >
                              
                              <div  className="stepContainer">
                                <Skeleton width="calc(33.3333333333% - 0.5em)" height="5px" backgroundType="light"/>
                                <Skeleton width="calc(33.3333333333% - 0.5em)" height="5px" backgroundType="light"/>
                                <Skeleton width="calc(33.3333333333% - 0.5em)" height="5px" backgroundType="light"/>
                              </div>
                              <Skeleton width="100%" height="31.5dvh" backgroundType="light">
                                <div className="flex justify-between items-center p-4">
                                  <Skeleton width="30%" height="5vh" backgroundType="dark"/>
                                  <Skeleton width="10%" height="5vh" backgroundType="dark"/>
                                </div>
                                <div className='flex flex-col gap-4 mt-6 mb-8 px-4'>
                                <Skeleton width="100%" height="5dvh" backgroundType="dark"/>
                                <Skeleton width="100%" height="5dvh" backgroundType="dark"/>
                                </div>
                                <div className="flex justify-between items-center px-4">
                                <Skeleton width="100%" height="5dvh" backgroundType="dark"/>
                                </div>
                              </Skeleton>

                              <div className="payer_container">
                                <div className="pb-2 flex flex-col gap-2 w-[90%]">
                                  <Skeleton width="90%" height="10px" backgroundType="light"/>
                                  <Skeleton width="40%" height="10px" backgroundType="light"/>
                                </div>
                                <Skeleton width="90%" height="55px" backgroundType="light"/>
                                <Skeleton width="90%" height="55px" backgroundType="light"/>
                              </div>
                             
                             
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div id="cartOrder" className={"min-h-fit px-6"+(loadingCart || !validationLoaded || isPayzenApplePayLoading ? "opacity-10 fixed w-10 transform left-[200vh]" : "")}>
                              
                          <div className="flex justify-between items-center">

                            {/* <h3 className="text-3xl">Produit (Maximum {store.item_limit})</h3> */}

                            <div className="flex items-center">
                              {checkedValues.length > 0 ? (
                                <button
                                  className="border-none "
                                  onClick={deletePopup}
                                >
                                  <img
                                    className=" h-8 w-auto"
                                    src="https://firebasestorage.googleapis.com/v0/b/pikkopay.appspot.com/o/Webapp%2Fcart%2Fcart_delete.png?alt=media&token=e416fc1a-35c3-44d7-868d-8cced82b717e"
                                    alt="delete"
                                  />
                                </button>
                              ) : (
                                <></>
                              )}
                              {/* <p className="ml-8 mr-2">Tout sélectionner :</p>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAllChange}
                            /> */}
                            </div>
                          </div>


                          <div className="checkout_contenair" style={{ fontFamily: 'Open Sans' }}>
                            <details className="checkout-details ">
                              <summary className="checkoutsummary ">
                                <thead className=" text-[16px] font-light" style={{ fontFamily: 'open_sansregular' }}>
                                <tr className="py-4">
                                  <th>{countItems} {countItems > 1 ? ('Produits') : ('Produit')}</th>
                                  <th><img src={v} alt="" srcSet="" /></th>
                                </tr>
                                </thead>

                              </summary>
                              {cartItems.orderItems.map((item, index) => {
                                return (<li key={index}>
                                    <div
                                      className="cart_list checkoutcartitems text-xl"
                                      style={{
                                        borderColor: item.CountInStock,
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400
                                      }}
                                    >
                                      {/* {item.price === 0 ? (
                                    <></>
                                  ) : (
                                    <input
                                      type="checkbox"
                                      value={item.Code_Barre}
                                      checked={checkedValues.some(
                                        (val) => val.product === item
                                      )}
                                      onChange={() => handleCheckboxChange(item)}
                                    />
                                  )} */}

                                      <div className="flex items-center justify-center overflow-hidden">
                                        <img
                                          src={item.image}
                                          alt={item.image}
                                          className="object-contain"
                                          style={{ height: '50px', width: '50px' }}
                                        ></img>

                                        <div className="pr-4 py-4 ml-4">
                                          <div className="min-30 text-left ">
                                            <div className="mb-1 flex flex-nowrap">
                                              {truncate(item.name, 26)}
                                            </div>

                                          </div>

                                        </div>
                                      </div>

                                      <div className="itemcartpricesmall text-black flex column ">
                                        <span className="text-black">Qté:{item.Qty}</span>
                                        <div className="price flex">
                                          {formatPriceWithEuro(item.promoPrice || item.price)}
                                          {
                                            item.promoPrice && item.price && (
                                              <span className="promotion_sliced">
                                                {formatPriceWithEuro(item.price)}
                                              </span>
                                            )
                                          }
                                        </div>

                                      </div>
                                      {/* {item.price === 0 ? (
                                    <></>
                                  ) : (
                                  <div className="min-30_price rounded-full border-solid px-6 py-1 flex items-center text-2xl relative containaire_ajout_retrait">
                                    <button
                                      className="border-none minusBtn "
                                      //onClick={() => deleteItem(item, index)}
                                      onClick={() =>
                                        removeFromCartHandler([
                                          { product: item, qty: 1 },
                                        ])
                                      }
                                    >
                                      -
                                    </button>
                                    {localQty[index]
                                      ? localQty[index]
                                      : item.Qty}

                                    {countItems >= store.item_limit ? (
                                      <></>
                                    ) : (
                                      <button
                                        className="border-none plusBtn "
                                        //onClick={() => addItem(item, index)}
                                        onClick={() => addToCartHandler(item)}
                                      >
                                        +
                                      </button>
                                    )}

                                  </div>
                                  )} */}

                                    </div>
                                  </li>
                                )
                              })}
                            </details>
                            <table className="infocheckout text-[16px] font-black"
                                   style={{ fontFamily: 'Open Sans, sans-serif' }}>

                              <tbody>
                              <tr className="pt-4">
                                <td className="opacity-[0.35]">Total</td>
                                <td className="td_int">{formatPriceWithEuro(cartItems.itemsPrice)}</td>
                              </tr>
                              <tr className="pb-4">
                                <td className="td_int promo_red">
                                  <img src={magicBrush} alt="magicBrush" className="magic_brush" />
                                  Promotion
                                </td>
                                <td
                                  className="td_int text-red-600 text-xl">{cartItems.promoPrice > 0 ? `- ${formatPriceWithEuro(cartItems.itemsPrice - cartItems.promoPrice)}` : formatPriceWithEuro(0)}
                                </td>
                              </tr>
                              {
                                user?.fidCard?.length > 0 && (
                                  <tr className="pb-4">
                                    <td className=" fidelity_green">carte de fidelité</td>
                                    <td
                                      className="td_int">
                                        <img src={fidelityCheck} alt="fidelityCheck" className="fidelity_check" />
                                    </td>
                                  </tr>
                                )
                              }
                              {cartItems?.promo.includes('campagne match 108048') && (
                                <tr className="pb-4">
                                  <td className="td_int opacity-[0.35]">Promo</td>
                                  <td
                                    className="td_int">Pikkopay -10%
                                  </td>
                                </tr>
                              )}


                              </tbody>
                              <tfoot>
                              <tr className="py-4">
                                <td colSpan="1">Total à payer</td>
                                <td className="td_int td_int_visible">
                                  <div className="geomanistBold">
                                  <div className="">
                                      <div className="">
                                        <div className="text-[16px] flex gap-[0.5em] "
                                             style={{ fontFamily: 'open_Bold' }}>
                                          {formatPriceWithEuro(getOrderAmount(cartItems))}
                                        </div>
                                      </div>
                                      {!isAllowedToPurchase && (
                                        <div className="">Montant minimum 1€</div>
                                      )}
                                      <div></div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              </tfoot>
                            </table>
                          </div>
                          {(!!cartItems && validationLoaded) && (
                            <CheckoutProvider>
                              <Elements stripe={stripe}>
                                <Checkout
                                  edenredErrorMessage={edenredErrorMessage}
                                  setEdenredErrorMessage={setEdenredErrorMessage}
                                  edenredIsLoading={edenredIsLoading}
                                  promoLoaded={validationLoaded}
                                  isPayzenApplePayLoading={isPayzenApplePayLoading}
                                  setIsPayzenApplePayLoading={setIsPayzenApplePayLoading}
                                />
                              </Elements>
                            </CheckoutProvider>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          id="loader"
                          className="loader loader-default is-active"
                          data-text="Chargement des données"
                        ></div>
                      </>
                    )}
                  </>
                )}
              </div>
            </>)}
        </>
      )}
    </>
  );
};

export default CartScreen;
