import {
  ADD_USER_APPLEPAY_EMAIL_FAIL,
  ADD_USER_APPLEPAY_EMAIL_REQUEST,
  ADD_USER_APPLEPAY_EMAIL_SUCCESS,
  ADD_USER_COUNT_FAIL,
  ADD_USER_COUNT_REQUEST,
  ADD_USER_COUNT_SUCCESS,
  ADD_USER_FAIL,
  ADD_USER_ID_FAIL,
  ADD_USER_ID_REQUEST,
  ADD_USER_ID_SUCCESS,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_USEDPROMO_FAIL,
  ADD_USER_USEDPROMO_REQUEST,
  ADD_USER_USEDPROMO_SUCCESS,
  DISCONNECT_USER_FAIL,
  DISCONNECT_USER_REQUEST,
  DISCONNECT_USER_SUCCESS,
  FIND_USER_FAIL,
  FIND_USER_REQUEST,
  FIND_USER_SUCCESS,
  MODIFY_USER_FAIL,
  MODIFY_USER_REQUEST,
  MODIFY_USER_SUCCESS,
  SAVE_USER_FAIL,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  SEND_USER_PROMOCODE_FAIL,
  SEND_USER_PROMOCODE_REQUEST,
  SEND_USER_PROMOCODE_SUCCESS,
  USER_CB_CARD_FAIL,
  USER_CB_CARD_SUCCESS,
  USER_CB_PAYZEN_CARD_SUCCESS,
  USER_CLAIM_GIFT_FAIL,
  USER_CLAIM_GIFT_REQUEST,
  USER_CLAIM_GIFT_SUCCESS,
  USER_GET_GIFT_FAIL,
  USER_GET_GIFT_REQUEST,
  USER_GET_GIFT_SUCCESS,
  USER_INITIAL_PROMO_FAIL,
  USER_INITIAL_PROMO_REQUEST,
  USER_INITIAL_PROMO_SUCCESS,
  USER_PURCHASE_PROMO,
} from "../constants/userConstants";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";
import { notifyError, notifySuccess, notifyWarning } from "../utils/Notify";
import { ACCOUNT, PROMO, ROOT, SCAN, USER_FID_CARD_DETAIL } from '../constants/routes';
import Swal from "sweetalert2";
import { isAppPWA } from '../utils/helper-functions';

export const addUser = (nom, prenom, email) => async (dispatch, getState) => {
  dispatch({ type: ADD_USER_REQUEST });
  try {
    dispatch({
      type: ADD_USER_SUCCESS,
      payload: { nom, prenom, email },
    });

    localStorage.setItem("user", JSON.stringify(getState().user));
  } catch (error) {
    console.log(error);
    dispatch({ type: ADD_USER_FAIL });
  }
};

export const addUserApplePayEmail =
  (user, axiosInstance) => async (dispatch, getState) => {
    console.log(user);

    dispatch(findUser(user.email, axiosInstance));

    dispatch({ type: ADD_USER_APPLEPAY_EMAIL_REQUEST });
    try {
      dispatch({
        type: ADD_USER_APPLEPAY_EMAIL_SUCCESS,
        payload: { email: user.apple_email },
      });

      localStorage.setItem("user", JSON.stringify(getState().user));
    } catch (error) {
      dispatch({ type: ADD_USER_APPLEPAY_EMAIL_FAIL });
    }
  };

// enregistrement cb
export const addUserId = (id) => async (dispatch, getState) => {
  dispatch({ type: ADD_USER_ID_REQUEST });
  try {
    dispatch({
      type: ADD_USER_ID_SUCCESS,
      payload: { id },
    });
    localStorage.setItem("user", JSON.stringify(getState().user));
  } catch (error) {
    console.log(error);
    dispatch({ type: ADD_USER_ID_FAIL });
  }
};

export const countPromo =
  (user, axiosInstance) => async (dispatch, getState) => {
    let count;

    dispatch({ type: ADD_USER_COUNT_REQUEST });
    if (user.exist || user.apple_email)
      await axiosInstance.put("/user/addCount", { user: user });

    try {
      switch (true) {
        case !user.count || user.count === undefined:
          count = 1;
          break;
        // case user.count === 3:
        //   count = 1
        //   break;
        case user.count > 0:
          count = user.count + 1;
          break;
        default:
          return;
      }

      dispatch({ type: ADD_USER_COUNT_SUCCESS, payload: count });
      localStorage.setItem("user", JSON.stringify(getState().user));
    } catch (error) {
      console.log(error);
      dispatch({ type: ADD_USER_COUNT_FAIL });
    }
  };

export const updatePayzenCards =
  (payzenCreditCards) => async (dispatch, getState) => {
    dispatch({ type: USER_CB_PAYZEN_CARD_SUCCESS, payload: payzenCreditCards });
    localStorage.setItem("user", JSON.stringify(getState().user));
  };

export const addUsedPromo =
  (user, axiosInstance, cartItems, saveCard, creditCard) =>
  async (dispatch, getState) => {
    dispatch({ type: ADD_USER_USEDPROMO_REQUEST });
    const usedPromo =
      user.usedPromo && Object.keys(user.usedPromo).length === 0
        ? []
        : user.usedPromo || [];
    user.currentPromo =
      user.currentPromo && Object.keys(user.currentPromo).length === 0
        ? []
        : user.currentPromo || [];
    cartItems.promo && cartItems.promo.length > 0 ? (
      usedPromo.push(cartItems.promo)
    ) : (
      <></>
    );

    axiosInstance
      .put("/user/addUsedPromo", {
        user: user,
        order: cartItems,
        creditCard,
        saveCard,
      })
      .then((response) => {
        dispatch({ type: ADD_USER_USEDPROMO_SUCCESS, payload: response.data });
        if (saveCard) {
          if (creditCard.isPayzen) {
            dispatch({
              type: USER_CB_PAYZEN_CARD_SUCCESS,
              payload: response.data.payzenCreditCards,
            });
          } else {
            dispatch({
              type: USER_CB_CARD_SUCCESS,
              payload: response.data.creditCard,
            });
          }
        }
        localStorage.setItem("user", JSON.stringify(getState().user));
        localStorage.setItem("previousOrder", JSON.stringify(cartItems));
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: ADD_USER_USEDPROMO_FAIL });
      });
  };

export const saveUser =
  (user, axiosInstance, order, navigate) => async (dispatch, getState) => {
    const isPWA = isAppPWA()
    if (!auth.currentUser || !auth.currentUser.metadata) {
      console.log("User metadata are not loaded", auth.currentUser);
      return;
    }
    dispatch({ type: SAVE_USER_REQUEST });
    const lastLoginAt = auth.currentUser.metadata.lastLoginAt;

    axiosInstance
      .post("/user", { user, order, lastLoginAt, isPWA })
      .then((response) => {
        dispatch({ type: SAVE_USER_SUCCESS, payload: response.data });
        localStorage.setItem("user", JSON.stringify(getState().user));

        if (localStorage.getItem("PREV_LOGIN_PAGE") === "invitation") {
          navigate(PROMO);
        }
        else if (localStorage.getItem("PREV_LOGIN_PAGE") === "fidScan"){
          notifySuccess("Carte ajoutée !");
          navigate(SCAN)
        }
        else if (
          localStorage.getItem("fidCardNum") &&
          response?.fidCard[0]?.length > 0
        ) {
          navigate(USER_FID_CARD_DETAIL);
        } else if(!localStorage.getItem("order") || !localStorage.getItem("store")) {
          navigate(ROOT)
        }
        else {
          navigate(ACCOUNT);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(logout(navigate));
        } else {
          console.error("Save user fail", error);
          dispatch({ type: SAVE_USER_FAIL });
        }
      });
  };
export const savePhoneNumberUser =
  (user, axiosInstance, order, navigate, action, setlockUntil, setError) =>
  async (dispatch, getState) => {
    const webService =
      action === "signup" ? "user/phoneNumberSignUp" : "user/phoneNumberSignIn";
    if (action === "signup") {
      if (!auth.currentUser || !auth.currentUser.metadata) {
        console.log("User metadata are not loaded", auth.currentUser);
        return;
      }
    }
    const isPWA = isAppPWA()
    dispatch({ type: SAVE_USER_REQUEST });
    const lastLoginAt = auth?.currentUser?.metadata?.lastLoginAt;
    setError(null);
    axiosInstance
      .post(webService, { user, order, lastLoginAt, isPWA })
      .then((response) => {
        dispatch({ type: SAVE_USER_SUCCESS, payload: response.data });
        localStorage.setItem("user", JSON.stringify(getState().user));

        if (localStorage.getItem("PREV_LOGIN_PAGE") === "invitation") {
          navigate(PROMO);
        }
        else if (localStorage.getItem("PREV_LOGIN_PAGE") === "fidScan"){
          notifySuccess("Carte ajoutée !");
          navigate(SCAN)
        }
        else if (
          localStorage.getItem("fidCardNum") &&
          response &&
          response.fidCard &&
          response.fidCard.length > 0
        ) {
          navigate(USER_FID_CARD_DETAIL);
        } 
        else if (localStorage.getItem("PREV_LOGIN_PAGE") === SCAN){
          navigate(SCAN);
          return;
        }
        else if(localStorage.getItem("isNewPWA") ){
          localStorage.removeItem("isNewPWA");
          navigate('/');
          return;
        }
        else{
          navigate(ACCOUNT);
        }
        
      })
      .catch((error) => {
        dispatch({ type: SAVE_USER_FAIL });
        if (error?.response?.status === 401) {
          dispatch(logout(navigate));
        } else if (error?.response?.status === 406) {
          if (error?.response?.data?.lockUntil) {
            localStorage.setItem("lockUntil", error?.response?.data?.lockUntil);
            setlockUntil(error?.response?.data?.lockUntil);
          } else {
            localStorage.removeItem("lockUntil");
            setlockUntil(null);
          }
          setError(error?.response?.data?.message);
          notifyError("Oups", error?.response?.data?.message);
        } else if (error?.response?.status === 409) {
          notifyError(
            "Oups",
            "Ce numéro de téléphone est déjà utilisé par un autre compte"
          );
        } else {
          console.error("Save user fail", error);
        }
      });
  };

  export const mergeAccounts = async(axiosInstance, firstAccountID, secondAccount,type, dispatch, user, navigate) => {
    try {
        const  data  = await axiosInstance.post('/user/synchronisation',
            {
                firstAccountID: firstAccountID,
                secondAccount: secondAccount,
                type: type
            }
        )
        if(data.status === 200){
          Swal.fire({
            title: data.data.title,
            text:data.data.message,
            showLoaderOnConfirm: true,
            icon: 'success',
            confirmButtonColor: '#FFD700',
            confirmButtonText: '<button class="payer w-full p-6 ">Ok</button>',
            buttonsStyling: false,
            preConfirm:async() => {
              
                localStorage.removeItem("fusionFirstAccount");
                try{const newUser = await dispatch(findUser(user, axiosInstance, navigate));
                if(newUser){
                  return newUser
                }}catch(e){
                  console.log(e)
                  return null
                }
  
  
              
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if(result.isConfirmed){
              Swal.close()
            }
        })

          return data.data
            
        }
        else{
          notifyError(data.data.message)
          localStorage.removeItem("fusionFirstAccount");
        }
    } catch (error) {
        console.log(error)
        notifyError(error.response.data.message)
    }
}



export const saveUserWithFid =
  (profile, axiosInstance, order, fidCardNum, navigate) =>
  async (dispatch, getState) => {
    if (!auth.currentUser || !auth.currentUser.metadata) {
      console.log("User metadata are not loaded", auth.currentUser);
      return;
    }
    dispatch({ type: SAVE_USER_REQUEST });

    const lastLoginAt = auth.currentUser.metadata.lastLoginAt;

    axiosInstance
      .post("/user/fid", { user: profile, order, lastLoginAt, fidCardNum })
      .then((response) => {
        dispatch({ type: SAVE_USER_SUCCESS, payload: response.data });
        localStorage.setItem("user", JSON.stringify(getState().user));

        if (localStorage.getItem("PREV_LOGIN_PAGE") === "invitation") {
          navigate(PROMO);
        } else {
          const fidCard = response.data.fidCard.find(
            (card) => fidCardNum === card.fidelityCardPhysicalId
          );
          navigate(USER_FID_CARD_DETAIL, {
            state: { fidCard, fromLogin: true },
          });
        }
      })
      .catch((error) => {
        dispatch({ type: SAVE_USER_FAIL });
        if (error.response.status === 401) {
          dispatch(logout(navigate));
        } else {
          console.error("Save user fail", error);
        }
        if (error.response.data.errorCode === "USER_FID_CARD_ALREADY_USED") {
          notifyError(
            "Oups",
            "L'email ne correspond à la carte de fidélité",
            navigate("/account")
          );
        } else {
          notifyError(
            "Oups",
            "Une erreur est survenue lors de ta connexion.",
            navigate("/account")
          );
        }
      });
  };

export const addFidCard =
  (axiosInstance, userId, fidCardNum, companyId, callBack) =>
  async (dispatch, getState) => {
    // TODO : secure CALL
    axiosInstance
      .post("user/add-fidelity-card", { userId, fidCardNum, companyId })
      .then((response) => {
        dispatch({ type: SAVE_USER_SUCCESS, payload: response.data });
        localStorage.setItem("user", JSON.stringify(getState().user));
        notifySuccess(
          "Carte de fidélité ajoutée avec succès.",
          "",
          "",
          callBack
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
        } else {
          console.error("Save user fail", error);
          dispatch({ type: SAVE_USER_FAIL });
        }
        notifyError(
          "Oups",
          "Une erreur est survenue lors de l'ajout de ta carte"
        );
      });
  };

export const findUser =
  (user, axiosInstance, navigate) => async (dispatch, getState) => {
    // if (!auth.currentUser || !auth.currentUser.metadata) {
    //   console.log('User metadata are not loaded', auth.currentUser);
    //   return;
    // }
    dispatch({ type: FIND_USER_REQUEST });
    // const lastLoginAt = auth.currentUser.metadata.lastLoginAt;
    const email = user?.email?.replace(/\s/g, "").toLowerCase() || undefined;

    axiosInstance
      .put("/user", { userId: user._id })
      .then((response) => {
        dispatch({ type: FIND_USER_SUCCESS, payload: response.data });
        localStorage.setItem("user", JSON.stringify(getState().user));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(logout(navigate));
        } else {
          console.error("Find user", error);
          localStorage.setItem("user", JSON.stringify(getState().user));
        }
        dispatch({ type: FIND_USER_FAIL });
      });
  };

const logout = (navigate) => async (dispatch, getState) => {
  dispatch(disconnectUser());
  signOut(auth).then(() => {
    if (navigate) {
      navigate(ACCOUNT);
    }
    localStorage.removeItem("popupUser");
    notifyWarning(
      "Session expirée",
      "Ta session est expirée, nous t'invitons à te reconnecter.",
      "D'accord"
    );
  });
};

export const sendUserPromoCode =
  (user, storeName, axiosInstance) => async (dispatch, getState) => {
    dispatch({ type: SEND_USER_PROMOCODE_REQUEST });
    try {
      user.email = user.email.replace(/\s/g, "").toLowerCase();
      if (!user.exist) {
        const { data } = await axiosInstance.post("/user", {
          user: user,
        });
        await axiosInstance.post("mail", {
          user: data.user,
          store_name: storeName,
        });
      } else {
        const { data } = await axiosInstance.put("/user", {
          email: user.email,
        });
        await axiosInstance.post("mail", {
          user: data,
          store_name: storeName,
        });
      }

      dispatch({ type: SEND_USER_PROMOCODE_SUCCESS });
      localStorage.setItem("user", JSON.stringify(getState().user));
    } catch (error) {
      console.log(error);
      dispatch({ type: SEND_USER_PROMOCODE_FAIL });
    }
  };

export const disconnectUser = () => async (dispatch, getState) => {
  dispatch({ type: DISCONNECT_USER_REQUEST });
  try {
    const user = JSON.parse(localStorage.getItem("user")) || {};
    const filteredPromoWelcomeUserInfo =
      user.currentPromo?.filter((item) => item.type.includes("welcome")) || [];

    console.log(filteredPromoWelcomeUserInfo);

    const newUserObject = {
      usedPromo: user.usedPromo || [],
      currentPromo: filteredPromoWelcomeUserInfo,
      appliedPromo: user.appliedPromo || [],
    };

    console.log(newUserObject);
    const newUserJSON = JSON.stringify(newUserObject);
    localStorage.setItem("user", newUserJSON);

    dispatch({ type: DISCONNECT_USER_SUCCESS, payload: newUserObject });
  } catch (error) {
    console.log("DISCONNECT_USER_FAIL", error);
    dispatch({ type: DISCONNECT_USER_FAIL });
  }
};

export const userInitialPromo =
  (user, axiosInstance) => async (dispatch, getState) => {
    dispatch({ type: USER_INITIAL_PROMO_REQUEST });
    try {
      const { data } = await axiosInstance.put("/user/initialPromo", {
        user: user,
      });
      const cb = localStorage.getItem("user");
      dispatch({ type: USER_INITIAL_PROMO_SUCCESS, payload: data });
      localStorage.setItem("user", JSON.stringify(getState().user));
    } catch (error) {
      console.log(error);
      dispatch({ type: USER_INITIAL_PROMO_FAIL });
      localStorage.setItem("user", JSON.stringify(getState().user));
    }
  };

export const getUserGift =
  (userId, axiosInstance) => async (dispatch, getState) => {
    dispatch({ type: USER_GET_GIFT_REQUEST });
    try {
      const { data } = await axiosInstance.get(`/user/gift/${userId}`);
      dispatch({ type: USER_GET_GIFT_SUCCESS, payload: data });
      localStorage.setItem("user", JSON.stringify(getState().user));
    } catch (error) {
      console.log(error);
      alert("Une erreur s'est produite");
      dispatch({ type: USER_GET_GIFT_FAIL });
      localStorage.setItem("user", JSON.stringify(getState().user));
      window.location.reload();
    }
  };

export const purchasePromo = (payload) => async (dispatch, getState) => {
  dispatch({ type: USER_PURCHASE_PROMO, payload });
  localStorage.setItem("user", JSON.stringify(getState().user));
};

export const claimUserGift =
  (userId, axiosInstance, promoItem) => async (dispatch, getState) => {
    dispatch({ type: USER_CLAIM_GIFT_REQUEST });
    try {
      const { data } = await axiosInstance.put(`/user/claimgift/${userId}`, {
        gift: promoItem,
      });
      console.log(data);
      dispatch({ type: USER_CLAIM_GIFT_SUCCESS, payload: data });
      localStorage.setItem("user", JSON.stringify(getState().user));
    } catch (error) {
      console.error(error);
      alert("Une erreur s'est produite");
      dispatch({ type: USER_CLAIM_GIFT_FAIL });
      localStorage.setItem("user", JSON.stringify(getState().user));
      window.location.reload();
    }
  };

export const modifyUserStore =
  (user, axiosInstance, storeId) => async (dispatch, getState) => {
    dispatch({ type: MODIFY_USER_REQUEST });
    try {
      const { data } = await axiosInstance.put("/user/addStore", {
        user: user,
        storeId: storeId,
      });
      dispatch({ type: MODIFY_USER_SUCCESS, payload: data.user });
      localStorage.setItem("user", JSON.stringify(getState().user));
      console.log(data);
    } catch (e) {
      dispatch({ type: MODIFY_USER_FAIL });
      localStorage.setItem("user", JSON.stringify(getState().user));
      console.log(e);
    }
  };

export const updateUserProfile =
  (userId, axiosInstance) => async (dispatch, getState) => {
    dispatch({ type: MODIFY_USER_REQUEST });
    try {
      const { data } = await axiosInstance.get("/user/profile", {
        params: { userId },
      });
      dispatch({ type: MODIFY_USER_SUCCESS, payload: data.user });
      localStorage.setItem("user", JSON.stringify(getState().user));
      // console.log(data);
    } catch (e) {
      dispatch({ type: MODIFY_USER_FAIL });
      localStorage.setItem("user", JSON.stringify(getState().user));
      console.log(e);
    }
  };
