import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import packageInfo from '../package.json';
import CacheBuster from 'react-cache-buster';
import {
  ACCOUNT,
  ADD_CB,
  ADD_FID_CARD,
  ADD_FID_SCAN,
  ADD_PAYMENT,
  BRAND,
  BRAND_DETAIL,
  CART,
  CHECK,
  COUPONS,
  EDENRED,
  FIDLOGIN,
  GIFT,
  HELP,
  HOME,
  INVITATION,
  LOGIN_FID_CARD,
  LOGIN_FID_SCAN,
  ORDER_SUCCESS,
  POPUP_PAYMENT,
  PRODUCT,
  PROMO,
  CONVERSION,
  RECOMPENSES,
  RESETPASSWORD,
  REWARD,
  SCAN,
  SCAN_CHECK,
  STORE_LIST,
  TICKETS,
  USER_FID_CARD,
  USER_FID_CARD_DETAIL,
  WALLET,
  NUMBER_SCREEN,
  SET_ACCOUNT,
} from './constants/routes';
import PageLoader from './components/loader/PageLoader';
import RedirectPage from './pages/RedirectPage'
import AccountScreen from './pages/account/AccountScreen'
import Scan from './components/Scan'
import NumberScreen from './pages/account/NumberScreen'
import StoreListScreen from './pages/StoreListScreen'
import WelcomeScreen from './pages/WelcomeScreen'
import CartScreen from './pages/CartScreen';
import { PullToRefreshComponent } from './utils/WsUtils';
import PullToRefresh from 'pulltorefreshjs';
import ReactDOMServer from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { isAppPWA } from './utils/helper-functions';

const UserFidelityCardDetail = React.lazy(() =>
                                            import('./pages/account/fid/UserFidelityCardDetail')
);
const UserAddFidCardScreen = React.lazy(() =>
                                          import('./pages/account/fid/add-card/UserAddFidCardScreen')
);
const UserAddFidScanScreen = React.lazy(() =>
                                          import('./pages/account/fid/add-card/UserAddFidScanScreen')
);
const SetAccountScreen = React.lazy(() =>
                                      import('./pages/account/setAccout/SetAccountScreen')
);
const UserFidelityCard = React.lazy(() =>
                                      import('./pages/account/fid/UserFidelityCard')
);

const ProductScreen = React.lazy(() => import('./pages/ProductScreen'));
const OrderCheck = React.lazy(() => import('./pages/OrderCheck'));
const OrderSuccess = React.lazy(() => import('./pages/OrderSuccess'));
const ScanCheck = React.lazy(() => import('./pages/ScanCheck'));
const EdenredScreen = React.lazy(() => import('./pages/EdenredScreen'));
const PopupPayment = React.lazy(() => import('./payments/PopupPayment'));
const GiftScreen = React.lazy(() => import('./pages/GiftScreen'));
const InvitationScreen = React.lazy(() => import('./pages/Invitation'));
const ResetPassword = React.lazy(() =>
                                   import('./pages/account/login/ResetPassword')
);
const PromoScreen = React.lazy(() => import('./pages/promo/PromoScreen'));
const PromoConversionScreen = React.lazy(() =>
                                           import('./pages/promo/PromoConversionScreen')
);
const TicketScreen = React.lazy(() => import('./pages/account/TicketScreen'));
const Recompenses = React.lazy(() => import('./pages/account/Recompense'));
const Coupon = React.lazy(() => import('./pages/account/Coupon'));
const Help = React.lazy(() => import('./pages/account/HelpScreen'));
const RewardScreen = React.lazy(() => import('./pages/gift/RewardScreen'));
const BrandDetailScreen = React.lazy(() =>
                                       import('./pages/brand/BrandDetailScreen')
);
const BrandPartnerScreen = React.lazy(() =>
                                        import('./pages/brand/BrandPartnerScreen')
);
const AddPaymentScreen = React.lazy(() =>
                                      import('./pages/account/addPayment/AddPaymentScreen')
);
const AddCbWrapper = React.lazy(() =>
                                  import('./pages/account/addPayment/AddCbWrapper')
);
const Wallet = React.lazy(() => import('./pages/account/addPayment/Wallet'));
const LoginAddFidCardScreen = React.lazy(() =>
                                           import('./pages/account/fid/add-card/LoginAddFidCardScreen')
);
const LoginAddFidScanScreen = React.lazy(() =>
                                           import('./pages/account/fid/add-card/LoginAddFidScanScreen')
);
const FidLoginScreen = React.lazy(() =>
                                    import('./pages/account/fid/FidLoginScreen')
);

function App() {
  useEffect(() => {
    if (document.querySelector('#pullToRefreshArea')) {
      PullToRefresh.init({
                           mainElement: '#pullToRefreshArea',
                           triggerElement: '#pullToRefreshArea',
                           shouldPullToRefresh(){
                             return isAppPWA()
                           },
                           onRefresh() {
                             window.location.reload();
                           },
                           iconArrow: ReactDOMServer.renderToString(
                             <FontAwesomeIcon icon={faArrowDown} size={'2x'} />
                           ),
                           iconRefreshing: ReactDOMServer.renderToString(
                             <FontAwesomeIcon icon={faSyncAlt} spin={true} size={'2x'} />
                           ),
                           instructionsPullToRefresh: ' ',
                           instructionsReleaseToRefresh: ' ',
                           instructionsRefreshing: ' ',

                         });
    }
  })
  window.scrollTo(0, 0);

  return (
    <>
      <div id="pullToRefreshArea">
        {/*{showLoader && <AnimatedLoader onComplete={handleAnimationEnd} />}*/}
        <CacheBuster currentVersion={packageInfo.version} isEnabled={true}>
          {/* <BrowserRouter> */}
          <div className="home">
            <main>
              {/*<PullToRefreshComponent />*/}
              <React.Suspense fallback={<PageLoader />}>
                <Routes>
                  <Route path="/" element={<RedirectPage />}></Route>
                  <Route path={STORE_LIST} element={<StoreListScreen />} />
                  <Route path={HOME} element={<WelcomeScreen />} />
                  <Route path={SCAN} element={<Scan />}></Route>
                  <Route path={INVITATION} element={<InvitationScreen />}></Route>
                  <Route path={PRODUCT} element={<ProductScreen />}></Route>
                  <Route path={EDENRED} element={<EdenredScreen />}></Route>
                  <Route path={CHECK} element={<OrderCheck />}></Route>
                  <Route path={ORDER_SUCCESS} element={<OrderSuccess />}></Route>
                  <Route path={SCAN_CHECK} element={<ScanCheck />}></Route>
                  <Route path={ACCOUNT} element={<AccountScreen />}></Route>
                  <Route
                    path={SET_ACCOUNT}
                    element={<SetAccountScreen />}
                  ></Route>
                  <Route path={RESETPASSWORD} element={<ResetPassword />}></Route>
                  <Route path={TICKETS} element={<TicketScreen />}></Route>
                  <Route path={COUPONS} element={<Coupon />}></Route>
                  <Route path={RECOMPENSES} element={<Recompenses />}></Route>
                  <Route path={HELP} element={<Help />}></Route>
                  <Route path={CART} element={<CartScreen />}></Route>
                  <Route path={POPUP_PAYMENT} element={<PopupPayment />} />
                  <Route path={GIFT} element={<GiftScreen />} />
                  <Route path={REWARD} element={<RewardScreen />} />
                  <Route path={BRAND_DETAIL} element={<BrandDetailScreen />} />
                  <Route path={BRAND} element={<BrandPartnerScreen />} />
                  <Route path={PROMO} element={<PromoScreen />} />
                  <Route path={CONVERSION} element={<PromoConversionScreen />} />
                  <Route path={WALLET} element={<Wallet />} />
                  <Route path={ADD_PAYMENT} element={<AddPaymentScreen />} />
                  <Route path={ADD_CB} element={<AddCbWrapper />} />
                  <Route
                    path={LOGIN_FID_CARD}
                    element={<LoginAddFidCardScreen />}
                  />
                  <Route
                    path={LOGIN_FID_SCAN}
                    element={<LoginAddFidScanScreen />}
                  />
                  <Route path={ADD_FID_CARD} element={<UserAddFidCardScreen />} />
                  <Route path={ADD_FID_SCAN} element={<UserAddFidScanScreen />} />
                  <Route path={FIDLOGIN} element={<FidLoginScreen />} />
                  <Route path={USER_FID_CARD} element={<UserFidelityCard />} />
                  <Route
                    path={USER_FID_CARD_DETAIL}
                    element={<UserFidelityCardDetail />}
                  />
                  <Route path={NUMBER_SCREEN} element={<NumberScreen />} />
                  {/* <Route path="/Recompense" element = {<Recompense/>} /> */}
                </Routes>
              </React.Suspense>
            </main>
          </div>
          {/* </BrowserRouter> */}
        </CacheBuster>
      </div>

    </>
  )
    ;
}

// git remote set-url origin https://github.com/lysot/Jilsfront.g
// npm install --legacy-peer-deps
export default App;
