import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe, } from '@stripe/react-stripe-js';
import React, { forwardRef, useEffect, useImperativeHandle, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { failPaidOrder, successPaidOrder } from '../../actions/cartActions';
import { addUsedPromo } from '../../actions/userActions';
import { removeCB } from '../../actions/userCbActions';
import dell from '../../styles/img/dell.svg'
import loadingPayment from '../../styles/lottie/loadingPayment.json'
import Lottie from 'lottie-react';
import { formatPriceWithEuro, getUserEmail } from '../../utils/StringUtils';
import { getOrderAmount } from '../../utils/PriceUtils';
import { CART_PAY_REQUEST } from '../../constants/cartConstants';
import { getErrorMessage } from '../../utils/WsUtils';
import { WsError } from '../../constants/wsError';

const CreditCard = ({
  axiosInstance, onCheckboxChange, paymentMethod, order, stopPaymentLoading, paymentErrorMessage, setPaymentErrorMessage, setIsPaid, resetPaymentMethod
}, ref) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const creditCard = useSelector((state) => state.user.creditCard) || {}
  const user = useSelector((state) => state.user)
  const [userName, setUserName] = useState(`${user.nom ?? ''} ${user.prenom ?? ''}`);
  const [focusedElement, setFocusedElement] = useState(null);
  const [cbPayment, setCbPayment] = useState('cb')
  const [cardNbComplete, setCardNbComplete] = useState();
  const [cardExpComplete, setCardExpComplete] = useState();
  const [cardCVCComplete, setCardCVCComplete] = useState();
  const [isContentVisible, setIsContentVisible] = useState(false)
  // const [isFormComplete, setIsFormComplete] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const cart = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);
  const { cartItems } = cart;

  const isChecked = paymentMethod === 'cb';

  const handleCheckboxChange = () => {
    onCheckboxChange();
  };

  function hasFirstAndLastName(str) {
    return str.split(/\s+/).filter(function (word) {
      return word.length > 0;
    }).length > 1;
  };

  useEffect(() => {
    if (elements && elements.getElement(CardNumberElement)) {
      elements.getElement(CardNumberElement).on('change', function (event) {
        const { complete } = event;
        if (complete) {
          setCardNbComplete(true);

        } else {
          setCardNbComplete(false);
        }
      });
      elements.getElement(CardExpiryElement).on('change', function (event) {
        const { complete } = event;
        if (complete) {
          setCardExpComplete(true);
        } else {
          setCardExpComplete(false);
        }
      });
      elements.getElement(CardCvcElement).on('change', function (event) {
        const { complete } = event;
        if (complete) {
          setCardCVCComplete(true);
        } else {
          setCardCVCComplete(false);
        }
      });
      elements.getElement(CardNumberElement).on('focus', function (event) {
        setFocusedElement(1);
      });
      elements.getElement(CardExpiryElement).on('focus', function (event) {
        setFocusedElement(2);
      });
      elements.getElement(CardCvcElement).on('focus', function (event) {
        setFocusedElement(3);
      });
      elements.getElement(CardNumberElement).on('blur', function (event) {
        setFocusedElement(false);
      });
      elements.getElement(CardExpiryElement).on('blur', function (event) {
        setFocusedElement(false);
      });
      elements.getElement(CardCvcElement).on('blur', function (event) {
        setFocusedElement(false);
      });
      //setFormCompletion(tempForm);
      //formComplete(tempForm);
    }
  }, [elements, creditCard]);

  useEffect(() => {
    if (cardNbComplete) {
      const expiryElement = elements.getElement(CardExpiryElement);
      if (expiryElement) {
        expiryElement.focus();
      }
    }
    if (cardExpComplete) {
      const cvcElement = elements.getElement(CardCvcElement);
      if (cvcElement) {
        cvcElement.focus();

      }
    }
  }, [cardNbComplete, cardExpComplete, elements]);

  useEffect(() => {
    if (cartItems.isPaid === true) {
      setLoading(false)
    }
  }, [cartItems.isPaid]);

  const stopLoading = () => {
    stopPaymentLoading();
    setLoading(false);
  };

  const hasSavedCB = creditCard && creditCard[0]?.pm && creditCard[0].pm.length > 0;

  const handleError = (errorMessage, errorLabel, errorContent) => {
    stopLoading();
    console.error(errorMessage, errorLabel, errorContent);
    setPaymentErrorMessage(errorMessage);
    dispatch(failPaidOrder(errorMessage));
  };

  const dispatchData = (data, creditCard) => {
    stopLoading();
    setIsPaid(true);
    dispatch(successPaidOrder(data));
    dispatch(addUsedPromo(user, axiosInstance, order, saveCard, creditCard)) // TODO : identifier ce qui fait quitte la page de paiement
  };

  const updateOrder = (paymentIntent, creditCard) => { // TODO : mettre en commun avec applepay
    axiosInstance.put(`/orders/${order._id}/pay`, { user, paymentIntent, methodName: 'cb' })
      .then((response) => {
        dispatchData(response.data, creditCard);
      })
      .catch((error) => {
        handleError(WsError.PAY_ORDER_ERROR, 'Error while calling payOrder endpoint', error);
      })
  };

  const confirmPayment = (clientSecret, creditCard) => {
    stripe.confirmCardPayment(clientSecret)
      .then((three_d) => {
        if (three_d.paymentIntent?.status === 'succeeded') {
          updateOrder(three_d.paymentIntent, creditCard);
        } else if (three_d.error) {
          handleError(WsError.STRIPE_PAYMENT_CONFIRM_ERROR, '3D payment verification failed, code :', three_d.error.code);
        } else {
          handleError(WsError.STRIPE_PAYMENT_CONFIRM_STATUS_ERROR, 'Cannot handle payment status in 3D payment, status :', three_d.paymentIntent?.status);
        }
      })
      .catch((error) => {
        handleError(WsError.STRIPE_PAYMENT_CONFIRM_ERROR, '3D payment verification failed, error :', error);
      })
  };

  const stripePayment = (orderAmount, paymentMethodId) => {
    dispatch({ type: CART_PAY_REQUEST, payload: { order, email: getUserEmail(user) } }); // TODO : utile ?
    axiosInstance.post('/stripe/payment/cb', {
      amount: orderAmount,
      storeId: order.storeId,
      pm: paymentMethodId,
      user: user,
      name: hasSavedCB ? undefined : userName,
      needToSaveCard: saveCard,
      orderId: order._id,
    })
      .then((response) => {
        const { data } = response;
        if (data.order) {
          dispatchData(data, data.creditCard);
        } else if (data.status === 'requires_action' && data.clientSecret) {
          confirmPayment(data.clientSecret, data.creditCard);
        } else {
          handleError(WsError.STRIPE_PAYMENT_INTENT_ERROR, 'Unexpected response from Card intent payment', data);
        }
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        handleError(errorMessage, 'Card intent payment failed', error);
      });
  };

  const createPaymentMethodAndPay = (orderAmount) => {
    const cardElement = elements.getElement(CardNumberElement);
    stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: userName,
        email: user.email
      }
    })
      .then((data) => {
        stripePayment(orderAmount, data.paymentMethod.id);
      })
      .catch((error) => {
        handleError(WsError.STRIPE_ADD_PAYMENT_METHOD_ERROR, 'Fail while creating payment method', error);
      })
  };

  const handleSubmit = async () => {
    setLoading(true);
    const orderAmount = getOrderAmount(order);
    if (hasSavedCB) {
      stripePayment(orderAmount, creditCard[0].pm);
    } else {
      if (hasFirstAndLastName(userName)) {
        createPaymentMethodAndPay(orderAmount);
      } else {
        alert('Veuillez renseigner un nom complet'); // TODO : mettre une vraie fenêtre d'erreur
        stopLoading();
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }))
  const handleFocus = (element) => {
    setFocusedElement(element);
  };

  const handleShowCreditCard = (e) => {
    handleCheckboxChange();
    if (e.target.checked) {
      setIsContentVisible(true)
    } else {
      setIsContentVisible(false)
    }
  };

  const handleInputChange = (e) => {
    setUserName(e.target.value);
  };

  const handleSaveCard = (state) => {
    let checkbox = document.querySelector('.checkbox');
    if (state.target.checked) {
      checkbox.classList.add('checked_svg');
    } else {
      checkbox.classList.remove('checked_svg');
    }
    setSaveCard(!saveCard);
  };
  const inputStyle = {
    color: 'rgba(0, 0, 0, 1)',
    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.5)',
      fontSize: '12px',
    },
  };
  const deletePopup = () => {
    let element = document.getElementById('deletepayment');
    element.classList.add('card')
    if (element.style.visibility === 'visible') {
      element.style.visibility = 'hidden';
    } else {
      element.style.visibility = 'visible';
    }
  };

  const toggleAccordion = () => {
    setIsContentVisible(!isContentVisible);
  };

  useEffect(() => {
    setIsContentVisible(isChecked);
  }, [isChecked]);

  return (
    <>
      {creditCard && creditCard[0] && creditCard[0].last4 ? (
        <div className="flex items-center formcarte text-black px-8 pr-14 w-full">
          <div className='flex w-full' onClick={handleCheckboxChange}>
            <input
              type="checkbox"
              checked={isChecked}
              id="choicecb"
              onChange={() => {
                handleCheckboxChange();
                toggleAccordion();
              }}
            />
            <label className="cbchecklabel mr-4" ></label>
            <div className="flex flex-row items-end gap-8 items-center">
              <img className="h-10 rounded-[5px] mr-2 " src={creditCard[0].img} />
              <div className="text-xl">.... {creditCard[0].last4} </div>
            </div>
          </div>
          <button
            onClick={async () => {
              await dispatch(removeCB(user._id, creditCard[0], axiosInstance));
              setIsContentVisible(false);
              resetPaymentMethod();
            }}
            className="deletepayment"
            style={{ marginLeft: 'auto' }}
          >
            <img src={dell} alt="" />
          </button>
        </div>

      ) : (
        <div style={{ boxShadow: '0px 3px 20px -10px rgba(0, 0, 0, 0.25)' }}>
          <div
            onClick={handleCheckboxChange}
            className={`flex items-center formcarte text-black px-8 pr-14 w-full ${isContentVisible ? 'slide-down-btn open' : ''}`}
          >
            <div className="cbandlabel flex  w-full">
              <div className="flex  w-[70%] items-center">
                <input
                  id="cbCheckbox"
                  name="cbCheckboxview"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleShowCreditCard}
                />
                <label className="cbchecklabel mr-4" onClick={handleCheckboxChange} />
                <p className="text-[16px]" style={{ fontFamily: 'geomanistRegular' }}>Carte bancaire</p>
              </div>
              <div className="cbimg flex gap-2 no-wrap">
                <img
                  className="h-16 rounded-[5px]"
                  src="https://storage.googleapis.com/pikkopay_images/webapp/payment/amex.png"
                />
                <img
                  className="h-16"
                  src="https://storage.googleapis.com/pikkopay_images/webapp/payment/visa.png"
                />
                <img
                  className="h-16"
                  src="https://storage.googleapis.com/pikkopay_images/webapp/payment/mastercard.png"
                />
              </div>
            </div>
          </div>
          <div>
            <div
              id="cbpopup" style={{ fontFamily: 'Open Sans' }}
              className={` slide-down-transition ${
                isContentVisible
                  ? 'slide-down-content visible '
                  : 'slide-down-content'
              }`}
            >
              <div className="mailformpayment rounded-[1em] margin-top">
                <label htmlFor="">
                  <h3 className="text-[1.5rem] tracking-tighter mb-2 ml-2">
                    Nom Prénom
                  </h3>
                </label>
                <input placeholder="Nom et prénom sur la carte" maxLength="20" type="text" required name="name"
                       id="nomprenom" value={userName === ' ' ? '' : userName} onChange={handleInputChange}
                       autoComplete="name" />
              </div>
                <div id="stripe" >
                  <div className="flex flex-col w-full p-2">
                    <label htmlFor="">
                      <h3 className="text-[1.5rem] tracking-tighter mb-2 ml-2" >
                        Numéro de carte
                      </h3>
                    </label>
                    <div onClick={()=>handleFocus(1)} className={`child1 `}>

                      <CardNumberElement
                        className={`stripe_card ${focusedElement === 1 ? "focused" : ""}`}
                        options={{ style: { base: inputStyle } }}

                      />
                    </div>
                    </div>


                    <div className="line w-full flex justify-between p-2">
                    <div className="w-[46%] flex flex-col ">
                    <label htmlFor="">
                        <h3 className="text-[1.5rem] tracking-tighter mb-2 ml-2">
                          Date d'expiration
                        </h3>
                      </label>
                      <div onClick={() => handleFocus(2)} className={`child2 `} >

                      <CardExpiryElement
                        className={`stripe_card ${focusedElement === 2 ? "focused" : ""}`}
                        id="expiryId"
                        options={{ style: { base: inputStyle } }}

                      />
                    </div>
                    </div>
                  <div className="w-[46%]  flex flex-col">
                    <label htmlFor="">
                      <h3 className="text-[1.5rem] tracking-tighter mb-2 ml-2" >
                        CVC
                      </h3>
                    </label>
                    <div onClick={() => handleFocus(3)} className={`child3`} >

                      <CardCvcElement
                        className={`stripe_card ${focusedElement === 3 ? "focused" : ""}`}
                        options={{ style: { base: inputStyle } }}

                      />
                    </div>
                  </div>
                  </div>
                </div>


              <input className="mr-4" id="cbcheckbox" type="checkbox" checked={saveCard} onChange={handleSaveCard} />

              <div className={'flex flex-col w-full'}>
                <label htmlFor="cbcheckbox" className="flex items-center ml-4 py-2">
                  <span className="checkbox"></span>
                  <h3 className="ml-4 text-[1.4rem] tracking-tighter" style={{ color: '#5A5A5A', fontWeight: 400 }}>
                    Enregistrer pour la prochaine fois
                  </h3>
                </label>
                {loading ? (
                  <div className="payer_maintenant_btn flex items-center bottom-btn-shadow">
                    <Lottie
                      animationData={loadingPayment}
                      loop={true}
                      autoplay={true}
                      style={{
                        width: '20px',
                        height: '20px',
                        top: '2.5px',
                        position: 'relative'
                      }} // Adjust the width and height as needed
                    />
                  </div>
                ) : (
                  <>
                    {paymentErrorMessage && (
                      <div className="payment-error">{paymentErrorMessage}</div>)
                    }
                    <button
                      className={`payer_maintenant_btn flex items-center bottom-btn-shadow ${
                        !cardCVCComplete || !cardExpComplete || !cardNbComplete || !hasFirstAndLastName(userName)
                          ? 'pointer-events-none backgray2 opacity-35'
                          : ''
                      } ${paymentErrorMessage ? 'error-message' : ''}`}
                      onClick={handleSubmit}
                      disabled={!cardCVCComplete || !cardExpComplete || !cardNbComplete || loading || !hasFirstAndLastName(userName)}
                    >
                      <p className="font-light " style={{ fontFamily: 'geomanistmedium' }}>Payer maintenant</p>
                      <p>{formatPriceWithEuro(getOrderAmount(cartItems))}</p>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default forwardRef(CreditCard);







