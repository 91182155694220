import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Edenred from './Edenred/Edenred';
import { selectPayment } from '../actions/payMethodActions';
import { useDispatch, useSelector } from 'react-redux';
import PaygreenConecs from './Paygreen/PaygreenConecs';
import goback_arrow from '../styles/img/goback_arrow.svg';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { checkButton } from './checkButton.js';
import { formatPriceWithEuro } from '../utils/StringUtils';
import { PositionDetector } from '../utils/DrawerPositionDetector.jsx';
import { getOrderAmount } from '../utils/PriceUtils';
import CreditCard from './Stripe/CreditCard';
import PayzenCreditCard from './Payzen/PayzenCreditCard';
import { isTRavailable } from '../utils/Validator';
import infoSvg from '../styles/img/info.svg';
import errorApplepayTRlogo from '../styles/img/account/passwordLogo.svg';
import PrimaryButton from '../components/button/PrimaryButton';
import closeArrow from '../styles/img/account/closeArrow.svg';
import WrongPaymentMethod from '../components/tile/WrongPaymentMethod';

const PopupPayment = (
  {
    open,
    openStatus,
    order,
    store,
    axiosInstance,
    edBalance,
    conecsBalance,
    cb,
    paymentErrorMessage,
    setPaymentErrorMessage,
    edenredErrorMessage,
    setEdenredErrorMessage,
    edenredIsLoading,
    isPayzenModule,
    promoLoaded,
    setPayzenToken,
  },
  ref
) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [checkedCB, setCheckedCB] = useState('');
  // const [isCBContentVisible, setIsCBContentVisible] = useState(false);
  // const [isTRContentVisible, setIsTRContentVisible] = useState(false);
  // const [cbForm, setCbForm] = useState();
  // const [validBtn, setValidBtn] = useState(true);
  const [payMethod, setPayMethod] = useState(undefined);
  const [isPaid, setIsPaid] = useState(false);
  const [applepayTRpopup, setApplepayTRpopup] = useState(false);
  const user = useSelector((state) => state.user);
  const targetNode = document.getElementById('spring-bottom-popuppayment');
  // console.log('targetNode:: ',targetNode)
  const [isPopupShadow, setIsPopupShadow] = useState(false);
  useEffect(() => {
    PositionDetector(targetNode, setIsPopupShadow, store, order);
  }, [targetNode]);

  const refComposant1 = useRef();
  const refComposant2 = useRef();
  const refComposant3 = useRef();

  const bottomSheetRef = useRef(null);

  // const selectCbPaymentMode = isPayzenModule
  //   ? user.payzenCreditCards && user.payzenCreditCards[0]?.last4
  //   : user.creditCard && user.creditCard[0]?.last4;

  // useEffect(() => {
  //   // TODO : ajouter dans le composant parent
  //   if (selectCbPaymentMode) {
  //     handleCheckboxChange("cb");
  //   } else if (user.conecs?.buyerId) {
  //     handleCheckboxChange("conecs");
  //   } else if (user.edenredInfo && user.edenredInfo.balance) {
  //     handleCheckboxChange("edenred");
  //   }
  // }, []);

  // useEffect(() => {
  //   if (checkedCB.includes("cb")) {
  //     setIsCBContentVisible(true);
  //     setValidBtn(!!cbForm);
  //   } else if (checkedCB.includes("conecs")) {
  //     setIsTRContentVisible(true);
  //     setValidBtn(!!cbForm);
  //   } else {
  //     setValidBtn(true);
  //     setIsCBContentVisible(false);
  //     setIsTRContentVisible(false);
  //   }
  //   cb(checkedCB);
  // }, [checkedCB]);

  // useEffect(() => {
  //   if (checkedCB.includes('cb')) {
  //     setValidBtn(!!cbForm);
  //   } else {
  //     setIsCBContentVisible(false);
  //   }
  // }, [cbForm]);

  const handleChange = (id) => {
    // setCheckedCB((prevCheckedId) => (prevCheckedId === id ? "" : id));
  };

  const handleBalance = (balanceEvent) => {
    edBalance(balanceEvent);
  };

  const resetPaymentMethod = () => {
    setPayMethod(undefined);
  };

  const handleCheckboxChange = (paymentMethod) => {
    // Réinitialise la méthode de paiement et programme le réglage de la nouvelle méthode
    const updatePaymentMethod = (method) => {
      resetPaymentMethod();
      setTimeout(() => {
        setPayMethod(method);
      }, 100);
    };

    // Si la méthode de paiement sélectionnée est déjà la méthode active, réinitialise la méthode
    if (payMethod === paymentMethod) {
      resetPaymentMethod();
    } else if (paymentMethod === 'conecs') {
      updatePaymentMethod('conecs');
    } else if (paymentMethod === 'cb') {
      updatePaymentMethod('cb');
    } else {
      updatePaymentMethod('edenred');
    }

    dispatch(selectPayment(paymentMethod));
  };

  const startLoading = () => {
    setLoading(true);
    setPaymentErrorMessage('');
    setEdenredErrorMessage('');
  };

  const test = async () => {
    if (payMethod && payMethod.length > 0) {
      switch (payMethod) {
        case 'cb':
          startLoading();
          try {
            await refComposant1.current.handleSubmit();
          } catch (e) {
            console.error(e);
            setLoading(false);
          }
          break;
        case 'conecs':
          startLoading();
          try {
            await refComposant2.current.handleSubmitPaygreen(order);
          } catch (e) {
            setLoading(false);
          }
          break;
        case 'edenred':
          startLoading();
          try {
            await refComposant3.current.handleSubmit(order);
          } catch (e) {
            setLoading(false);
          }

          break;
        default:
          alert('Pas de méhode de paiement choisie');
      }
    }
  };
  useEffect(() => {
    console.log('payMethod', payMethod)
    if(user?.creditCard && user?.creditCard?.[0]?.pm && user?.creditCard?.[0]?.pm?.length > 0 && payMethod == undefined && !isPayzenModule){
      setPayMethod('cb')
    }
    else if(user?.payzenCreditCards && user?.payzenCreditCards?.[0]?.pm && user.payzenCreditCards?.[0]?.pm?.length > 0 && payMethod == undefined && isPayzenModule){
      setPayMethod('cb')
    }
  }, [])

  useEffect(() => {
    if (openStatus && bottomSheetRef.current) {
      bottomSheetRef.current.snapTo(1000)
      open(false)
    }
  }, [openStatus])

  const hasActivePaygreenCard =
    isPayzenModule &&
    user.payzenCreditCards &&
    Object.keys(user.payzenCreditCards).length &&
    user.payzenCreditCards[0]?.pm &&
    user.payzenCreditCards[0].pm.length > 0;
  const hasActiveStripeCard =
    !isPayzenModule &&
    user.creditCard &&
    Object.keys(user.creditCard).length &&
    user.creditCard[0]?.pm &&
    user.creditCard[0].pm.length > 0;
  const hasActiveCard = hasActivePaygreenCard || hasActiveStripeCard;
  const hasActiveConecs = user.conecs && Object.keys(user.conecs).length > 0;
  const hasActiveEdenred = user.edenred && Object.keys(user.edenred).length > 0;

  const footerElement =
    (hasActiveCard || hasActiveConecs || hasActiveEdenred) &&
    checkButton(payMethod, user, isPayzenModule) &&
    !isPaid ? (
      <div>
        {paymentErrorMessage && (
          <div className="payment-error footer">{paymentErrorMessage}</div>
        )}
        <button
          disabled={loading || !payMethod || !payMethod.length > 0}
          onClick={test}
          className={`pt-10 shadow-none w-[100%] py-10 z-4 h-10 rounded-[10px] ${
            payMethod && payMethod.length > 0 ? 'pikko-btn' : 'grayscale'
          }`}
        >
          {loading ? (
            <p>Chargement...</p>
          ) : (
            <div className="flex w-full justify-between px-8 text-[1.7rem]">
              <p>Payer maintenant</p>
              <p>{formatPriceWithEuro(getOrderAmount(order))}</p>
            </div>
          )}
        </button>
      </div>
    ) : undefined;
  const handleWrongPaymentMethod = () => {
    open(true)
    setApplepayTRpopup(false)
  }

  return (
    <>
      {applepayTRpopup && (
      <WrongPaymentMethod
        onClose={() => setApplepayTRpopup(false)}
        onClickPrimary={() => handleWrongPaymentMethod()}
      />
      )}
      <BottomSheet
        ref={bottomSheetRef}
        open={true}
        onDismiss={() => {
          bottomSheetRef.current.snapTo(0);
          open(false)
        }}
        snapPoints={({ maxHeight }) =>
          store.name.toUpperCase().includes('MATCH')
            ? [maxHeight - maxHeight + 1, maxHeight * 0.85]
            : [maxHeight - maxHeight + 1, maxHeight * 0.75]
        }
        blocking={isPopupShadow}
        id="spring-bottom-popuppayment"
        scrollLocking={false}
        className="payment-slider"
        footer={footerElement}
      >
        <div>
          <div
            id="paymentPopup"
            className={
              'paymentPopup z-50 $ rounded-tr-[30px] rounded-tl-[30px] py-16 pt-8 flex flex-col'
            }
          >
            <div className="payment-popup-content">
              <div
                className="flex items-center titre_popup "
                onClick={() => {
                  bottomSheetRef.current.snapTo(0);
                  open(false);
                }}
              >
                <span>Méthode de paiement</span>
                <div className="closepopup2 ">
                  <img src={goback_arrow} alt="" srcSet="" />
                </div>
              </div>
              <div className="w-full ">
                <>
                  <div id="paymentTab">
                    <div className="w-full mt-8 ">
                      {isPayzenModule ? (
                        <PayzenCreditCard
                          onCheckboxChange={() => handleCheckboxChange('cb')}
                          paymentMethod={payMethod}
                          paymentErrorMessage={paymentErrorMessage}
                          resetPaymentMethod={resetPaymentMethod}
                          ref={refComposant1}
                          order={order}
                          promoLoaded={promoLoaded}
                          setPayzenToken={setPayzenToken}
                          setTRpopup={() => setApplepayTRpopup(true)}

                        />
                      ) : (
                        <CreditCard
                          order={order}
                          axiosInstance={axiosInstance}
                          onCheckboxChange={() => handleCheckboxChange('cb')}
                          resetPaymentMethod={resetPaymentMethod}
                          paymentMethod={payMethod}
                          ref={refComposant1}
                          stopPaymentLoading={() => setLoading(false)}
                          paymentErrorMessage={paymentErrorMessage}
                          setPaymentErrorMessage={setPaymentErrorMessage}
                          setIsPaid={setIsPaid}

                        />
                      )}
                    </div>
                  </div>
                </>

                {/* Edenred */}
                {/* Conecs */}

                {isTRavailable(order.orderItems) ? (
                  <>
                    <div id="paymentTab" className="">
                      <PaygreenConecs
                        onCheckboxChange={() => handleCheckboxChange('conecs')}
                        ref={refComposant2}
                        order={order}
                        paymentMethod={payMethod}
                        stopPaymentLoading={() => setLoading(false)}
                        setPaymentErrorMessage={setPaymentErrorMessage}
                      />
                    </div>
                    <div id="paymentTab">
                      <Edenred
                        balance={handleBalance}
                        onChange={handleChange}
                        order={order}
                        onCheckboxChange={() => handleCheckboxChange('edenred')}
                        resetPaymentMethod={resetPaymentMethod}
                        paymentMethod={payMethod}
                        ref={refComposant3}
                        stopPaymentLoading={() => setLoading(false)}
                        setEdenredErrorMessage={setEdenredErrorMessage}
                        edenredIsLoading={edenredIsLoading}
                        setIsPaid={setIsPaid}
                      />
                      <div className="payment-error">{edenredErrorMessage}</div>
                    </div>
                  </>
                ) : (
                  <div className="no-eligible">
                    <img src={infoSvg} alt="" srcSet="" />
                    Panier non éligible aux cartes ticket restaurant
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </BottomSheet>
    </>
  );
};
export default forwardRef(PopupPayment);
